import Splide from '@splidejs/splide';

document.addEventListener('DOMContentLoaded', function () {
  if (window.locale === 'en') return;
  const main = new Splide('.regions-slider', {
    // fixedWidth: 314.5,
    autoWidth: true,
    gap: 32,
    rewind: true,
    pagination: false,
    arrows: false,
    drag: false,
    breakpoints: {
      1360: {
        drag: true,
      },
      480: {
        autoWidth: false,
      }
    },
  });

  const thumbnails = new Splide('.regions-slider-thumbnails', {
    gap: 0,
    rewind: true,
    pagination: false,
    autoWidth: true,
    drag: false,
    arrows: false,
    isNavigation: true,
    i18n: {
      slideX: '',
    }
  });

  main.sync(thumbnails);
  main.mount();
  thumbnails.mount();

});