const burgerMenuBtn = document.querySelector('.hamburger-menu');

const burgerMenu = document.querySelector('.header-popup');
const headerCategoriesListBtns = document.querySelector('.header-categories').querySelectorAll('.header-categories__mainItem-btn');

burgerMenuBtn.addEventListener('click', () => {
  burgerMenuBtn.classList.toggle('hamburger-menu_active');
  burgerMenu.classList.toggle('header-popup_active');
  document.body.classList.toggle('body-lock');
});

// Toggle header categories
headerCategoriesListBtns.forEach((el) => {
  el.addEventListener('click', () => {
    const headerCategoriesList = el.parentElement.parentElement;
    headerCategoriesList.classList.toggle('header-categories__item_active');
  });
})