import Splide from '@splidejs/splide';
const navbarBtnToShow = document.querySelector('.bottom-navbar-btn');
const navbarBtnToHide = document.querySelector('.bottom-navbar__close');
const bottomNavbar = document.querySelector('.bottom-navbar');
const bottomNavbarWrapper = document.querySelector('.bottom-navbar-wrapper');

const cookies = document.querySelector('.cookie');
let isNavbarHidden = false;
let isCookieHidden = false;

let imagesLoaded = false;
window.addEventListener('scroll', () => {
  if (!bottomNavbarWrapper || imagesLoaded) return;
  const currentScrollPos = window.pageYOffset;
  if (currentScrollPos > 10) {
    bottomNavbarWrapper.querySelectorAll('img').forEach((img) => {
      img.classList.remove('lazy');
      img.src = img.dataset.src;
    });
    imagesLoaded = true;
    bottomNavbarWrapper.classList.add('bottom-navbar-wrapper_opened');
  }
});

if (cookies && bottomNavbar) {
  !cookies.classList.contains('cookie_hidden') && bottomNavbar.classList.add('bottom-navbar_opened-with-cookies');
  window.addEventListener('scroll', () => {
    if (document.cookie.includes('cookiecook')) return;
    const currentScrollPos = window.pageYOffset;

    // window.screen.height for real device height
    if (currentScrollPos > 860) {
      bottomNavbar.classList.remove('bottom-navbar_opened-with-cookies')
      navbarBtnToShow.classList.remove('bottom-navbar-btn_cookie')
      cookies.classList.add('cookie_hidden')
      if (!isNavbarHidden) {
        bottomNavbar.classList.remove('bottom-navbar_opened-with-cookies')
      }
      isCookieHidden = true;
    }
    // else {
    //   if (!isNavbarHidden) {
    //     bottomNavbar.classList.add('bottom-navbar_opened-with-cookies')
    //   } else {
    //     navbarBtnToShow.classList.add('bottom-navbar-btn_cookie')
    //   }
    //   cookies.classList.remove('cookie_hidden')
    // }
  });
}

if (navbarBtnToShow) {
  navbarBtnToShow.addEventListener('click', () => {
    bottomNavbar.classList.add('bottom-navbar_opened')
    navbarBtnToShow.classList.add('bottom-navbar-btn_hidden')
    isNavbarHidden = false;
    if (cookies && !document.cookie.includes('cookiecook') && !isCookieHidden) {
      // cookies.classList.add('cookie_with-navbar');
      bottomNavbar.classList.add('bottom-navbar_opened-with-cookies')
    }
  });

  navbarBtnToHide.addEventListener('click', () => {
    bottomNavbar.classList.remove('bottom-navbar_opened')
    bottomNavbar.classList.remove('bottom-navbar_opened-with-cookies')
    navbarBtnToShow.classList.remove('bottom-navbar-btn_hidden')
    isNavbarHidden = true;
    if (cookies) {
      cookies.classList.remove('cookie_with-navbar');
    }
  });

  document.addEventListener('DOMContentLoaded', function () {
    const handleNavbarLoad = () => {
      let splide = new Splide('.splide-bottom-navbar', {
        type: 'loop',
        autoWidth: true,
        pagination: false,
        arrows: true,
        perPage: 1,
        // speed: 100,
        // dragMinThreshold: 52,
        // clones: 2,
        // cloneStatus: false,
        flickPower: 35,
        rewindByDrag: true,
        gap: 22,
        breakpoints: {
          375: {
            gap: 22,
          },
          390: {
            gap: 25,
          },
          3000: {
            gap: 30,
          },
        },
        fixedHeight: 58,
        focus: 'center',
        updateOnMove: true,
      });

      splide.on('click', function (instance) {
        const slideImg = instance.slide.querySelector('.bottom-navbar__item-image');
        const slideTitle = slideImg.nextElementSibling;
        if (instance.slide.classList.contains('navbar-dark-mode-switcher')) {
          const hasAttr = bottomNavbar.toggleAttribute('data-isDark');
          const imgs = bottomNavbar.querySelectorAll('img') || [];

          if (hasAttr) {
            slideImg.innerHTML = `
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.4086 20.3767C17.7052 20.3767 20.3777 17.7043 20.3777 14.4076C20.3777 11.1109 17.7052 8.43848 14.4086 8.43848C11.1119 8.43848 8.43945 11.1109 8.43945 14.4076C8.43945 17.7043 11.1119 20.3767 14.4086 20.3767Z" fill="white" stroke="white" stroke-width="1.13" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.0514 17.6038L24.7974 18.7472M18.6842 24.8154L17.5588 22.0604M11.2206 22.0424L10.0772 24.7884M6.75498 17.5498L4 18.6752M4.02701 10.0592L6.77299 11.2026M10.1312 4L11.2566 6.75498M18.7382 4.02701L17.5948 6.77299M24.8154 10.1402L22.0604 11.2656" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

            `;
            slideTitle.innerHTML = window.lightModeTrans;
            imgs.forEach((itm) => {
              if (itm.classList.contains('white')) {
                itm.classList.remove('hidden-sm');
              } else {
                itm.classList.add('hidden-sm');
              }
            })
          } else {
            slideImg.innerHTML = `
            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.9221 6.5C17.7498 7.769 18.2356 9.272 18.2356 10.901C18.2356 15.338 14.6368 18.938 10.2013 18.938C9.32864 18.938 8.48293 18.794 7.7002 18.533C9.13971 20.72 11.6049 22.169 14.4209 22.169C18.8564 22.169 22.4552 18.569 22.4552 14.132C22.4552 10.568 20.134 7.553 16.9221 6.5Z" fill="#2D2D2D" stroke="#2D2D2D" stroke-width="1.52" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>         
            `;
            slideTitle.innerHTML = window.darkModeTrans;
            imgs.forEach((itm) => {
              if (itm.classList.contains('black')) {
                itm.classList.remove('hidden-sm');
              } else {
                itm.classList.add('hidden-sm');
              }
            })
          }

          if (document.cookie.includes('navbarDarkMode=no')) {
            document.cookie = `navbarDarkMode=yes; path=/; domain=.${window.location.hostname};`
            return;
          }
          document.cookie = `navbarDarkMode=no; path=/; domain=.${window.location.hostname};`
        }

      });

      // we mount two times because isNavigation isn't working properly
      // with focus: center
      splide.mount();
      // options = { ...options, isNavigation: true };

      // splide = new Splide('.splide-bottom-navbar', options);
      // splide.mount();
    }
    // only article page has this class
    // const articleOnlyTag = document.querySelector('.article-author__image');
    const articleOnlyTag = false;
    if (articleOnlyTag) {
      var script = document.createElement('script');

      // Set the src attribute to the Splide.js CDN URL
      script.src = "/static/news/js/splide.min.js";

      // Append the script element to the document head
      document.head.appendChild(script);

      script.onload = function () {
        handleNavbarLoad();
      };
    } else {
      handleNavbarLoad();
    }
  });

}
